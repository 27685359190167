import { action, thunk } from 'easy-peasy'
import { headers, responseHandler } from 'utils/api'
import { urlParams } from 'utils/url'

export default {
  // latest accepted terms
  latestAcceptedTerms: { status: 0, payload: null },
  setLatestAcceptedTerms: action((state, payload = null) => {
    state.latestAcceptedTerms = payload
  }),
  getLatestAcceptedTerms: thunk(async (actions) => {
    actions.setLatestAcceptedTerms({ status: 1, payload: null })

    const response = await fetch(
      '/api/guest_service/legal_terms/v1/get_accepted_terms',
      {
        method: 'GET',
        headers: { ...headers.contentTypeJson }
      }
    )

    const { status, payload } = await responseHandler(response)
    actions.setLatestAcceptedTerms({ status, payload })
  }),
  // legal terms version
  legalTermsVersion: { status: 0, payload: null },
  setLegalTermsVersion: action((state, payload = null) => {
    state.legalTermsVersion = payload
  }),
  getLegalTermsVersion: thunk(async (actions) => {
    actions.setLegalTermsVersion({ status: 1, payload: null })

    const response = await fetch(
      '/api/guest_service/legal_terms/v1/get_legal_terms_version',
      {
        method: 'GET',
        headers: { ...headers.contentTypeJson }
      }
    )

    const { status, payload } = await responseHandler(response)
    actions.setLegalTermsVersion({ status, payload })
  }),
  // update guest latest accepted terms
  updatedAcceptedTerms: { status: 0, payload: null },
  setUpdatedAcceptedTerms: action((state, payload = null) => {
    state.updatedAcceptedTerms = payload
  }),
  postUpdateAcceptedTerms: thunk(async (actions, data) => {
    actions.setUpdatedAcceptedTerms({ status: 1, payload: null })

    const response = await fetch(
      '/api/guest_service/legal_terms/v1/update_accepted_terms',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { ...headers.contentTypeJson }
      }
    )

    const { status, payload } = await responseHandler(response)
    actions.setUpdatedAcceptedTerms({ status, payload })
  }),
  legalTerms: { status: 0, payload: null },
  setLegalTerms: action((state, payload = null) => {
    state.legalTerms = payload
  }),
  getLegalTerms: thunk(async (actions, params) => {
    actions.setLegalTerms({ status: 1, payload: null })

    const response = await fetch(
      `/api/guest_service/legal_terms/v1/get_legal_terms?${urlParams(params)}`,
      {
        method: 'GET',
        headers: { ...headers.contentTypeJson }
      }
    )

    const { status, payload } = await responseHandler(response)
    actions.setLegalTerms({ status, payload })
  })
}
