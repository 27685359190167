import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // State
  uploadImageState: { status: 0, payload: null },
  // Setter (Action)
  setUploadImageState: action((state, payload = null) => {
    state.uploadImageState = payload
  }),
  // Action Creator
  uploadImage: thunk(async (actions, data) => {
    actions.setUploadImageState({ status: 1, payload: null })
    const formData = new FormData()
    formData.append('image', data.image)

    const response = await fetch('/api/zeus_parameter/images/upload', {
      method: 'post',
      body: formData,
      headers: { ...defaultHeaders }
    })
    const { status, payload } = await responseHandler(response)
    actions.setUploadImageState({ status, payload })
  })
}
