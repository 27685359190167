import { thunk, action } from 'easy-peasy'

// 0 - Idle
// 1 - Pending
// 2 - Fulfilled
// 3 - Error

export default {
  // State
  scGetVouchersState: { status: 0, payload: null },
  // Setter (Action)
  setScGetVouchersState: action((state, payload = null) => {
    state.scGetVouchersState = payload
  }),
  // Action Creator
  scGetVouchers: thunk(async (actions, data) => {
    actions.setScGetVouchersState({ status: 1, payload: null })

    try {
      const response = await fetch('/api/supercard/sc_getvouchers', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status >= 200 && response.status <= 204) {
        const isSuccess = { status: 2, payload }
        const isError = { status: 3, payload }
        actions.setScGetVouchersState(payload.IsSuccess === 'true' ? isSuccess : isError)
      } else {
        throw payload
      }
    } catch (error) {
      actions.setScGetVouchersState({ status: 3, payload: 'Internal Server Error' })
    }
  })
}
