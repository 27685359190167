import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // Login via facebook
  convertedFacebookToken: { status: 0, payload: null },
  setFacebookConvert: action((state, payload = null) => {
    state.convertedFacebookToken = payload
  }),
  convertFacebookToken: thunk(async (actions, data) => {
    actions.setFacebookConvert({ status: 1, payload: null })

    const response = await fetch('/api/auth/social/convert_facebook_token', {
      method: 'post',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setFacebookConvert({ status, payload })
  }),
  // Login via google
  convertedGoogleToken: { status: 0, payload: null },
  setGoogleConvert: action((state, payload = null) => {
    state.convertedGoogleToken = payload
  }),
  convertGoogleToken: thunk(async (actions, data) => {
    actions.setGoogleConvert({ status: 1, payload: null })

    const response = await fetch('/api/auth/social/convert_google_token', {
      method: 'post',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setGoogleConvert({ status, payload })
  })
}
