import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'

export default {
  // Create guest address
  contactUsState: { status: 0, payload: null },
  setContactUsState: action((state, payload = null) => {
    state.contactUsState = payload
  }),
  contactUs: thunk(async (actions, data) => {
    actions.setContactUsState({ status: 1, payload: null })

    const response = await fetch('/api/guest_service/contact_us', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setContactUsState({ status, payload })
  })

}
