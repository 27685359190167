import { thunk, action } from 'easy-peasy'

const headers = { 'Content-Type': 'application/json' }

export default {
  // Pricing
  pricing: { status: 0, payload: null },
  setPricing: action((state, payload = null) => {
    state.pricing = payload
  }),
  getPricing: thunk(async (actions, data) => {
    actions.setPricing({ status: 1, payload: null })
    try {
      const response = await fetch('/api/order/pricing', {
        method: 'post',
        body: JSON.stringify(data),
        headers: headers
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setPricing({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setPricing({ status: 3, payload: error })
    }
  })
}
