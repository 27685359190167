import { createStore } from 'easy-peasy'

// Zeus Auth
import oauth from './zeus_auth/oauth'
import socialOauth from './zeus_auth/social_oauth'
import user from './zeus_auth/user'
import register from './zeus_auth/register'
import updateMe from './zeus_auth/update_me'
import otpActivation from './zeus_auth/otp_activation'
import requestOtp from './zeus_auth/request_otp'
import verifyOtp from './zeus_auth/verify_otp'
import resetPassword from './zeus_auth/reset_password'

// Orders
import updateTrackingStatus from './orders/update_tracking_status'

// Zeus Order
import pricing from './zeus_order/pricing'
import orders from './zeus_order/orders'
import submitFeedback from './zeus_order/feedback'

// Zeus Legacy
import pdsOrderStatus from './zeus_legacy/pds_order_status'

// Zeus Location
import validation from './zeus_location/validation'
import tracking from './zeus_location/tracking'

// RDS Order Status
import rdsOS from './rds_service/rds_order_status'

import carts from './zeus_order/carts/index'
import cartItems from './zeus_order/carts/items'
import pastOrders from './zeus_order/carts/past_orders'

// Zeus Catalog
import products from './zeus_catalog/products'
import categories from './zeus_catalog/categories'
// Zeus Parameter
import provinces from './zeus_parameter/provinces'
import cities from './zeus_parameter/cities'
import upload from './zeus_parameter/upload'
import address from './guest/address'
import recipient from './guest/recipient'
import guestSelf from './guest/self'
import deliveryPricing from './zeus_parameter/delivery_pricing'
import contactUs from './guest/contact_us'
import paymentOptions from './zeus_parameter/payment_options'
// miscellenous
import miscellenous from './misc'

// SuperCard+
import scRegister from './supercard/sc_register'
import scValidate from './supercard/sc_validate'
import scInquiry from './supercard/sc_inquiry'
import scGetCards from './supercard/sc_getcards'
import scGetPoints from './supercard/sc_getpoints'
import scRedeemVoucher from './supercard/sc_redeemvoucher'
import scGetVouchers from './supercard/sc_getvouchers'
import scWelcomeTreat from './supercard/sc_welcome_treat'

// Peri peri promotions
import promos from './promos'

// payments
import payments from './payments'

// ZEUS store service
import storeSerivce from './zeus_store_service'

// News
import news from './news'

// supercard v2
import superCardV2 from './supercard_v2'

// legal terms
import legalTerms from './guest/legal_terms'

// guest modal prompts
import prompts from './guest/prompts'

export default createStore({
  // Zeus Auth
  ...oauth,
  ...socialOauth,
  ...user,
  ...register,
  ...updateMe,
  ...otpActivation,
  ...requestOtp,
  ...verifyOtp,
  ...resetPassword,
  // Zeus Catalog
  ...categories,
  ...products,
  // Zues Parameter
  ...provinces,
  ...cities,
  ...upload,
  ...deliveryPricing,
  ...contactUs,
  ...paymentOptions,
  // miscellenous
  ...miscellenous,
  // SuperCard
  ...scRegister,
  ...scValidate,
  ...scInquiry,
  ...scGetCards,
  ...scGetPoints,
  ...scRedeemVoucher,
  ...scGetVouchers,
  ...scWelcomeTreat,
  // RDS Order Status
  ...rdsOS,
  // Zeus Order
  ...carts,
  ...cartItems,
  ...pastOrders,
  // Zeus Legacy
  ...pdsOrderStatus,
  // Zeus Location
  ...validation,
  ...tracking,
  ...pricing,
  ...orders,
  ...submitFeedback,
  // guest services
  ...address,
  ...recipient,
  ...guestSelf,
  // promotions
  ...promos,
  // payements
  ...payments,
  // orders
  ...updateTrackingStatus,
  // ZEUS Store service
  ...storeSerivce,
  ...news,
  // zeus supercard v1
  ...superCardV2,
  ...legalTerms,
  // guest prompts
  ...prompts
})
