import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // State
  updateMeState: { status: 0, payload: null },
  // Setter (Action)
  setUpdateMeState: action((state, payload = null) => {
    state.updateMeState = payload
  }),
  // Action Creator
  updateMe: thunk(async (actions, data) => {
    actions.setUpdateMeState({ status: 1, payload: null })

    const response = await fetch('/api/auth/update_me', {
      method: 'put',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)

    actions.setUpdateMeState({ status, payload })
  })
}
