import { thunk, action } from 'easy-peasy'
import { urlParams } from 'utils/url'
const getHeaders = {
  method: 'get',
  headers: { 'Content-Type': 'application/json' }
}
const postHeader = {
  ...getHeaders.headers,
  'Cache-Control': 'no-cache'
}
export default {
  // Carts
  carts: { status: 0, payload: null },
  setCarts: action((state, payload = null) => {
    state.carts = payload
  }),
  getCarts: thunk(async (actions, data) => {
    actions.setCarts({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts?${urlParams(data)}`, getHeaders)
      const payload = await response.json()
      if (response.status === 200) {
        actions.setCarts({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setCarts({ status: 3, payload: error })
    }
  }),
  // Cart Details
  cart: { status: 0, payload: null },
  setCart: action((state, payload = null) => {
    state.cart = payload
  }),
  getCart: thunk(async (actions, id) => {
    actions.setCart({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts/${id}`, getHeaders)
      const payload = await response.json()
      if (response.status === 200) {
        actions.setCart({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setCart({ status: 3, payload: error })
    }
  }),
  createdCart: { status: 0, payload: null },
  setCreatedCart: action((state, payload = null) => {
    state.createdCart = payload
  }),
  createCart: thunk(async (actions, data) => {
    actions.setCreatedCart({ status: 1, payload: null })
    try {
      const response = await fetch('/api/carts', {
        method: 'post',
        body: JSON.stringify(data),
        headers: postHeader
      })
      const payload = await response.json()
      if (response.status >= 200 && response.status <= 204) {
        actions.setCreatedCart({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setCreatedCart({ status: 3, payload: error })
    }
  }),
  updatedCart: { status: 0, payload: null },
  setUpdatedCart: action((state, payload = null) => {
    state.updatedCart = payload
  }),
  updateCart: thunk(async (actions, { id, data }) => {
    actions.setUpdatedCart({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts/${id}`, {
        method: 'put',
        body: JSON.stringify(data),
        headers: postHeader
      })
      const payload = await response.json()
      if (response.status >= 200 && response.status <= 204) {
        actions.setUpdatedCart({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setUpdatedCart({ status: 3, payload: error })
    }
  }),
  deletedCart: { status: 0, payload: null },
  setDeletedCart: action((state, payload = null) => {
    state.deletedCart = payload
  }),
  deleteCart: thunk(async (actions, id) => {
    actions.setDeletedCart({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts/${id}`, {
        method: 'delete',
        headers: postHeader
      })
      const payload = await response.json()
      if (response.status >= 200 && response.status <= 204) {
        actions.setDeletedCart({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setDeletedCart({ status: 3, payload: error })
    }
  }),

  // pending cart items
  pendingCartItem: null,
  setPendingCartItem: action((state, item = null) => {
    state.pendingCartItem = item
  })
}
