import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // State
  verifyOtpState: { status: 0, payload: null },
  // Setter (Action)
  setVerifyOtpState: action((state, payload = null) => {
    state.verifyOtpState = payload
  }),
  // Action Creator
  verifyOtp: thunk(async (actions, data) => {
    actions.setVerifyOtpState({ status: 1, payload: null })

    try {
      const response = await fetch('/api/auth/verify_otp', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          ...defaultHeaders,
          'Cache-Control': 'no-cache'
        }
      })
      const { status, payload } = await responseHandler(response)
      actions.setVerifyOtpState({ status, payload })
    } catch (error) {
      actions.setVerifyOtpState({ status: 3, payload: error.message })
    }
  }),
  otpConfirmState: '',
  setOtpConfirmState: action((state, payload = null) => {
    state.otpConfirmState = payload
  })
}
