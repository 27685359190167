import { thunk, action } from 'easy-peasy'
import { urlParams } from 'utils/url'

export default {
  // RDS Order Status
  rdsOS: { status: 0, payload: null },
  setRdsOS: action((state, payload = null) => {
    state.rdsOS = payload
  }),
  getRdsOS: thunk(async (actions, data) => {
    actions.setRdsOS({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/rds_service/rds_order_status?${urlParams(data)}`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setRdsOS({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setRdsOS({ status: 3, payload: error })
    }
  })
}
