import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'

export default {
  // update guest self
  updateGuestState: { status: 0, payload: null },
  setUpdateGuestState: action((state, payload = null) => {
    state.updateGuestState = payload
  }),
  updateGuest: thunk(async (actions, params) => {
    actions.setUpdateGuestState({ status: 1, payload: null })

    const response = await fetch(`/api/guest_service/update_guest/${params.id}`, {
      method: 'put',
      body: JSON.stringify(params.data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setUpdateGuestState({ status, payload })
  })
}
