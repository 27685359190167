import { clientTokenHandler } from 'utils/oauth'
import styled from 'styled-components'

export async function getServerSideProps ({ req, res }) {
  const cToken = await clientTokenHandler(req, res)

  if (!cToken) {
    return {
      redirect: {
        permanent: false,
        destination: '/503'
      }
    }
  }

  return {
    props: {
      error: !cToken
    }
  }
}

const StyeldWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url('/images/maintenance-desktop.png');
  background-size: cover;
  background-position: center center;

  @media (max-width: 562px) {
    background-image: url('/images/maintenance-mobile.png');
  }
`

export default function Index (props) {
  return (
    <StyeldWrapper />
  )
}
