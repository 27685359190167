import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'

export default {
  // Create guest address
  createAddressState: { status: 0, payload: null },
  setCreateAddressState: action((state, payload = null) => {
    state.createAddressState = payload
  }),
  createAddress: thunk(async (actions, data) => {
    actions.setCreateAddressState({ status: 1, payload: null })

    const response = await fetch('/api/guest_service/address/create', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setCreateAddressState({ status, payload })
  }),

  // list guest addresses
  listAddressState: { status: 0, payload: null },
  setListAddressState: action((state, payload = null) => {
    state.listAddressState = payload
  }),
  listAddress: thunk(async (actions, id) => {
    actions.setListAddressState({ status: 1, payload: null })

    const response = await fetch(`/api/guest_service/address/list?id=${id}`, {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setListAddressState({ status, payload })
  }),

  // delete guest address
  deleteAddressState: { status: 0, payload: null },
  setDeleteAddressState: action((state, payload = null) => {
    state.deleteAddressState = payload
  }),
  deleteAddress: thunk(async (actions, id) => {
    actions.setDeleteAddressState({ status: 1, payload: null })

    const response = await fetch(`/api/guest_service/address/delete/${id}`, {
      method: 'delete',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setDeleteAddressState({ status, payload })
  }),

  // update guest address
  updateAddressState: { status: 0, payload: null },
  setUpdateAddressState: action((state, payload = null) => {
    state.updateAddressState = payload
  }),
  updateAddress: thunk(async (actions, params) => {
    actions.setUpdateAddressState({ status: 1, payload: null })

    const response = await fetch(`/api/guest_service/address/update/${params.id}`, {
      method: 'put',
      body: JSON.stringify(params.data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setUpdateAddressState({ status, payload })
  }),

  // Modal to trigger new location / edit location
  newLocationModal: false,
  setNewLocationModal: action((state, payload = null) => {
    state.newLocationModal = payload
  }),

  editLocationValue: null,
  setEditLocationValue: action((state, payload = null) => {
    state.editLocationValue = payload
  })
}
