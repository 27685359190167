import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'

const idle = { status: 0, payload: null }
const pending = { status: 1, payload: null }

export default {
  news: idle,
  setNews: action((state, payload = null) => {
    state.news = payload
  }),
  getNews: thunk(async (actions) => {
    actions.setNews(pending)

    const response = await fetch('/api/news', {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setNews({ status, payload })
  }),

  newsDetails: idle,
  setNewsDetails: action((state, payload = null) => {
    state.newsDetails = payload
  }),
  getNewsDetails: thunk(async (actions, id) => {
    actions.setNewsDetails(pending)

    const response = await fetch(`/api/news/${id}`, {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setNewsDetails({ status, payload })
  })
}
