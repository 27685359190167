import { isJSON } from 'utils/string'

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const headers = {
  contentTypeJson: { 'Content-Type': 'application/json' }
}

export const serverResponseHandler = async (response) => {
  const text = await response.text()
  const status = response.status
  const payload = isJSON(text) ? JSON.parse(text) : text
  return { status, payload }
}

export const responseHandler = async (response) => {
  const text = await response.text()
  const status = response.ok ? 2 : 3
  const payload = isJSON(text) ? JSON.parse(text) : text
  return { status, payload }
}

export const ORDER_ASCEND = 'created_at'
export const ORDER_DESCEND = '-created_at'
export const PRODUCT_ORDER_BY_NAME = 'product_name'
export const PRODUCT_ORDER_BY_PRICE = 'product_price'
export const PRODUCT_ORDER_BY_SORTORDER = 'category_sort_order'
