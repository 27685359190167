import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'
import { urlParams } from 'utils/url'

const idle = { status: 0, payload: null }

export default {
  // Products
  products: idle,
  setProducts: action((state, payload = null) => {
    state.products = payload
  }),
  getProducts: thunk(async (actions, data = {}) => {
    actions.setProducts({ status: 1, payload: null })

    const response = await fetch(`/api/catalog/products?${urlParams(data)}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setProducts({ status, payload })
  }),
  productBySlug: idle,
  setProductBySlug: action((state, payload = null) => {
    state.productBySlug = payload
  }),
  getProductBySlug: thunk(async (actions, slug) => {
    actions.setProductBySlug({ status: 1, payload: null })

    const response = await fetch(`/api/catalog/product_by_slug/${slug}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setProductBySlug({ status, payload })
  }),
  product: { status: 0, payload: null },
  setProduct: action((state, payload = null) => {
    state.product = payload
  }),
  getProduct: thunk(async (actions, id) => {
    actions.setProduct({ status: 1, payload: null })

    const response = await fetch(`/api/catalog/products/${id}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setProduct({ status, payload })
  }),
  featuredProducts: { status: 0, payload: null },
  setFeaturedProducts: action((state, payload = null) => {
    state.featuredProducts = payload
  }),
  getFeaturedProducts: thunk(async (actions, data = {}) => {
    actions.setFeaturedProducts({ status: 1, payload: null })

    const response = await fetch(`/api/catalog/featured_products?${urlParams(data)}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setFeaturedProducts({ status, payload })
  })
}
