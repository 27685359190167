import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // Categories
  categories: { status: 0, payload: null },
  setCategories: action((state, payload = null) => {
    state.categories = payload
  }),
  getCategories: thunk(async (actions, data) => {
    actions.setCategories({ status: 1, payload: null })

    const response = await fetch('/api/catalog/categories', {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setCategories({ status, payload })
  })
}
