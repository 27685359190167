import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'

export default {
  // Create guest recipient
  createRecipientState: { status: 0, payload: null },
  setCreateRecipientState: action((state, payload = null) => {
    state.createRecipientState = payload
  }),
  createRecipient: thunk(async (actions, data) => {
    actions.setCreateRecipientState({ status: 1, payload: null })

    const response = await fetch('/api/guest_service/recipient/create', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setCreateRecipientState({ status, payload })
  }),

  // list guest recipient
  listRecipientState: { status: 0, payload: null },
  setListRecipientState: action((state, payload = null) => {
    state.listRecipientState = payload
  }),
  listRecipient: thunk(async (actions, id) => {
    actions.setListRecipientState({ status: 1, payload: null })

    const response = await fetch('/api/guest_service/recipient/', {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setListRecipientState({ status, payload })
  }),

  // delete guest recipient
  deleteRecipientState: { status: 0, payload: null },
  setDeleteRecipientState: action((state, payload = null) => {
    state.deleteRecipientState = payload
  }),
  deleteRecipient: thunk(async (actions, id) => {
    actions.setDeleteRecipientState({ status: 1, payload: null })

    const response = await fetch(`/api/guest_service/recipient/delete/${id}`, {
      method: 'delete',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setDeleteRecipientState({ status, payload })
  }),

  // update guest address
  updateRecipientState: { status: 0, payload: null },
  setUpdateRecipientState: action((state, payload = null) => {
    state.updateRecipientState = payload
  }),
  updateRecipient: thunk(async (actions, params) => {
    actions.setUpdateRecipientState({ status: 1, payload: null })

    const response = await fetch(`/api/guest_service/recipient/update/${params.id}`, {
      method: 'put',
      body: JSON.stringify(params.data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setUpdateRecipientState({ status, payload })
  }),

  // global stores for handling recipient modal
  newRecipientModal: false,
  setNewRecipientModal: action((state, payload = null) => {
    state.newRecipientModal = payload
  }),

  editRecipientValues: null,
  setEditRecipientValues: action((state, payload = null) => {
    state.editRecipientValues = payload
  })
}
