import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'
import { urlParams } from 'utils/url'

export default {
  // Cities
  cities: { status: 0, payload: null },
  setCities: action((state, payload = null) => {
    state.cities = payload
  }),
  getCities: thunk(async (actions, data) => {
    actions.setCities({ status: 1, payload: null })

    const response = await fetch(`/api/zeus_parameter/cities?${urlParams(data)}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setCities({ status, payload })
  })
}
