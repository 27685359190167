import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  deliveryPricing: { status: 0, payload: null },
  setDeliveryPricing: action((state, payload = null) => {
    state.deliveryPricing = payload
  }),
  getDeliveryPricing: thunk(async (actions, data) => {
    actions.setDeliveryPricing({ status: 1, payload: null })

    const response = await fetch('/api/zeus_parameter/delivery_pricing', {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setDeliveryPricing({ status, payload })
  })
}
