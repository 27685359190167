import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'
import { isJSON } from 'utils/string'

export default {
  // welcome treats product
  welcomeTreat: { status: 0, payload: null },
  setWelcomeTreat: action((state, payload = null) => {
    state.welcomeTreat = payload
  }),
  getWelcomeTreat: thunk(async (actions, params = {}) => {
    actions.setWelcomeTreat({ status: 1, payload: null })
    const { type } = params

    const response = await fetch(`/api/catalog/welcome_treat/${type}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)
    actions.setWelcomeTreat({ status, payload })
  }),
  welcomeTreatPricing: { status: 0, payload: null },
  setWelcomeTreatPricing: action((state, payload = null) => {
    state.welcomeTreatPricing = payload
  }),
  getWelcomeTreatPricing: thunk(async (actions, data) => {
    actions.setWelcomeTreatPricing({ status: 1, payload: null })

    try {
      const response = await fetch('/api/order/pricing', {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setWelcomeTreatPricing({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setWelcomeTreatPricing({ status: 3, payload: error })
    }
  }),
  // middleware used to creating cart item when the item is supercard
  createdScCartItem: { status: 0, payload: null },
  setCreatedScCartItem: action((state, payload = null) => {
    state.createdScCartItem = payload
  }),
  createScCartItem: thunk(async (actions, { cartId, ...data }) => {
    actions.setCreatedScCartItem({ status: 1, payload: null })

    const response = await fetch(`/api/carts/items/${cartId}`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    })
    if (response.ok) {
      const json = await response.json()
      actions.setCreatedScCartItem({ status: 2, payload: json })
    } else {
      const text = await response.text()
      if (isJSON(text)) {
        actions.setCreatedScCartItem({ status: 3, payload: JSON.parse(text) })
      } else {
        actions.setCreatedScCartItem({ status: 3, payload: text })
      }
    }
  }),
  // used when adding to cart supercard product
  superCardToBuy: { sc: null, quantity: 0 },
  setSuperCardToBuy: action((state, payload = null) => {
    state.superCardToBuy = payload
  }),
  //   registered new sc
  registeredNewSc: false,
  setRegisteredNewSc: action((state, payload = null) => {
    state.registeredNewSc = payload
  }),
  // to set selected welcome treat
  selectedTreat: [],
  setSelectedTreat: action((state, payload = null) => {
    state.selectedTreat = payload
  })
}
