import { thunk, action } from 'easy-peasy'

export default {
  // Polygon
  polygon: { status: 0, payload: null },
  setPolygon: action((state, payload = null) => {
    state.polygon = payload
  }),
  polygonValidation: thunk(async (actions, data) => {
    actions.setPolygon({ status: 1, payload: null })

    try {
      const response = await fetch('/api/zeus_location/validation', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setPolygon({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setPolygon({ status: 3, payload: error })
    }
  })
}
