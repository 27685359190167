import { action } from 'easy-peasy'

export default {
  /**
   * used to set success contents
   * eg. Type, title, body message, action
   * sample Payload
   * setSuccessType({
        title: 'CONGRATULATIONS',
        type: 'Password Reset',
        message: 'You have successfully nominated a new password. Go to the login page and try to log in.',
        actions: [
          {
            btn_content: 'Go to Login Page',
            path_to: '/auth/login'
          }
        ]
      })
   */
  successType: null,
  setSuccessType: action((state, payload = null) => {
    state.successType = payload
  }),
  // used to set drawer in store locator mobile filters.
  isDrawerOpen: false,
  setIsDrawerOpen: action((state, payload = null) => {
    state.isDrawerOpen = payload
  }),
  // account sharing modal
  accSharingModalVisible: false,
  setAccSharingModalVisible: action((state, payload = null) => {
    state.accSharingModalVisible = payload
  }),
  // agree setter
  agreedToShareAcc: false,
  setAgreedToShareAcc: action((state, payload = null) => {
    state.agreedToShareAcc = payload
  })
}
