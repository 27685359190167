import { thunk, action } from 'easy-peasy'
import { isJSON } from 'utils/string'
// import { urlParams } from 'utils/url'

const getHeaders = {
  method: 'get',
  headers: { 'Content-Type': 'application/json' }
}

export default {
  // CartItems
  cartItems: { status: 0, payload: null },
  setCartItems: action((state, payload = null) => {
    state.cartItems = payload
  }),
  getCartItems: thunk(async (actions, cartId) => {
    actions.setCartItems({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts/items/${cartId}`, getHeaders)
      const payload = await response.json()
      if (response.status === 200) {
        actions.setCartItems({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setCartItems({ status: 3, payload: error })
    }
  }),
  // Cart Item Details
  cartItem: { status: 0, payload: null },
  setCartItem: action((state, payload = null) => {
    state.cartItem = payload
  }),
  getCartItem: thunk(async (actions, cartId, id) => {
    actions.setCartItem({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts/items/${cartId}/${id}`, getHeaders)
      const payload = await response.json()
      if (response.status === 200) {
        actions.setCartItem({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setCartItem({ status: 3, payload: error })
    }
  }),
  createdCartItem: { status: 0, payload: null },
  setCreatedCartItem: action((state, payload = null) => {
    state.createdCartItem = payload
  }),
  createCartItem: thunk(async (actions, { cartId, ...data }) => {
    actions.setCreatedCartItem({ status: 1, payload: null })

    const response = await fetch(`/api/carts/items/${cartId}`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...getHeaders.headers,
        'Cache-Control': 'no-cache'
      }
    })
    if (response.ok) {
      const json = await response.json()
      actions.setCreatedCartItem({ status: 2, payload: json })
    } else {
      const text = await response.text()
      if (isJSON(text)) {
        actions.setCreatedCartItem({ status: 3, payload: JSON.parse(text) })
      } else {
        actions.setCreatedCartItem({ status: 3, payload: text })
      }
    }
  }),
  updatedCartItem: { status: 0, payload: null },
  setUpdatedCartItem: action((state, payload = null) => {
    state.updatedCartItem = payload
  }),
  updateCartItem: thunk(async (actions, { cartId, itemId, ...data }) => {
    actions.setUpdatedCartItem({ status: 1, payload: null })

    const response = await fetch(`/api/carts/items/${cartId}/?itemId=${itemId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        ...getHeaders.headers,
        'Cache-Control': 'no-cache'
      }
    })
    if (response.ok) {
      const json = await response.json()
      actions.setUpdatedCartItem({ status: 2, payload: json })
    } else {
      const text = await response.text()
      if (isJSON(text)) {
        actions.setUpdatedCartItem({ status: 3, payload: JSON.parse(text) })
      } else {
        actions.setUpdatedCartItem({ status: 3, payload: text })
      }
    }
  }),
  deletedCartItem: { status: 0, payload: null },
  setDeletedCartItem: action((state, payload = null) => {
    state.deletedCartItem = payload
  }),
  deleteCartItem: thunk(async (actions, { cartId, itemId }) => {
    actions.setDeletedCartItem({ status: 1, payload: null })

    const response = await fetch(`/api/carts/items/${cartId}/?itemId=${itemId}`, {
      method: 'delete',
      headers: {
        ...getHeaders.headers,
        'Cache-Control': 'no-cache'
      }
    })
    const payload = await response.text()
    try {
      actions.setDeletedCartItem({ status: 2, payload: payload })
    } catch (error) {
      actions.setDeletedCartItem({ status: 3, payload: error })
    }
  })
}
