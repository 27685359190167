import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'

export default {
  promos: { status: 0, payload: null },
  setGetPromos: action((state, payload = null) => {
    state.promos = payload
  }),
  getPromos: thunk(async (actions, id) => {
    actions.setGetPromos({ status: 1, payload: null })

    const response = await fetch('/api/promos', {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setGetPromos({ status, payload })
  }),
  promoProduct: { status: 0, payload: null },
  setPromoProduct: action((state, payload = null) => {
    state.promoProduct = payload
  }),
  getPromoProduct: thunk(async (actions, id) => {
    actions.setPromoProduct({ status: 1, payload: null })

    const response = await fetch(`/api/promos/products/${id}`, {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setPromoProduct({ status, payload })
  })
}
