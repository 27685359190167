import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'
import { urlParams } from 'utils/url'

export default {
  recentOrdersState: { status: 0, payload: null },
  setGetRecentOrdersState: action((state, payload = null) => {
    state.recentOrdersState = payload
  }),
  getRecentOrders: thunk(async (actions) => {
    actions.setGetRecentOrdersState({ status: 1, payload: null })

    const response = await fetch('/api/order/recent_order', {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setGetRecentOrdersState({ status, payload })
  }),
  cancelledOrder: { status: 0, payload: null },
  setCancelledOrder: action((state, payload = null) => {
    state.cancelledOrder = payload
  }),
  postCancelOrder: thunk(async (actions, transactionNumber) => {
    actions.setCancelledOrder({ status: 1, payload: null })

    const response = await fetch(`/api/orders/cancel_order/${transactionNumber}`, {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setCancelledOrder({ status, payload })
  }),
  orderStatus: { status: 0, payload: null },
  setOrderStatus: action((state, payload = null) => {
    state.orderStatus = payload
  }),
  getOrderStatus: thunk(async (actions, id) => {
    actions.setOrderStatus({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/orders/order_status/${id}`, {
        method: 'get',
        headers: {
          ...defaultHeaders,
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setOrderStatus({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setOrderStatus({ status: 3, payload: error })
    }
  }),

  // order mode modal trigger
  orderModeModal: false,
  setOrderModeModal: action((state, payload = null) => {
    state.orderModeModal = payload
  }),
  // selectedDelivery state
  deliverySelectedAddress: null,
  setDeliveryAddress: action((state, payload = null) => {
    state.deliverySelectedAddress = payload
  }),

  editOrderMode: null,
  setEditOrderMode: action((state, payload = null) => {
    state.editOrderMode = payload
  }),
  // selected mode delivery / send a peri / pickup a peri
  selectedMode: 'delivery', // delivery as default since send a peri is not in the MVP.
  setSelectedMode: action((state, payload = null) => {
    state.selectedMode = payload
  }),
  // used to store contact info on edit
  editContactInfo: null,
  setEditContactInfo: action((state, payload = null) => {
    state.editContactInfo = payload
  }),

  // Orders
  orders: { status: 0, payload: null },
  setOrders: action((state, payload = null) => {
    state.orders = payload
  }),
  getOrders: thunk(async (actions, data) => {
    actions.setOrders({ status: 1, payload: null })

    try {
      const response = await fetch(`/api/orders?${urlParams(data)}`, {
        method: 'get',
        headers: {
          ...defaultHeaders,
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setOrders({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setOrders({ status: 3, payload: error })
    }
  }),
  // Order Details
  orderDetails: { status: 0, payload: null },
  setOrderDetails: action((state, payload = null) => {
    state.orderDetails = payload
  }),
  getOrderDetails: thunk(async (actions, id) => {
    actions.setOrderDetails({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/orders/${id}`, {
        method: 'get',
        headers: {
          ...defaultHeaders,
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setOrderDetails({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setOrderDetails({ status: 3, payload: error })
    }
  }),
  // Create orders
  createdOrder: { status: 0, payload: null },
  setCreatedOrder: action((state, payload = null) => {
    state.createdOrder = payload
  }),
  createOrder: thunk(async (actions, data) => {
    actions.setCreatedOrder({ status: 1, payload: null })
    try {
      const response = await fetch('/api/orders', {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status >= 200 && response.status <= 204) {
        actions.setCreatedOrder({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setCreatedOrder({ status: 3, payload: error })
    }
  }),
  // Update Order Status
  updatedOrderStatus: { status: 0, payload: null },
  setUpdateOrderStatus: action((state, payload = null) => {
    state.updatedOrderStatus = payload
  }),
  updateOrderStatus: thunk(async (actions, id, data) => {
    actions.setUpdateOrderStatus({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/orders/${id}`, {
        method: 'put',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setUpdateOrderStatus({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setUpdateOrderStatus({ status: 3, payload: error })
    }
  }),
  deletedPendingOrder: { status: 0, payload: null },
  setDeletedPendingOrder: action((state, payload = null) => {
    state.deletedPendingOrder = payload
  }),
  postDeletePendingOrder: thunk(async (actions, payload = {}) => {
    actions.setDeletedPendingOrder({ status: 1, payload: null })
    const { id, data } = payload
    try {
      const response = await fetch(`/api/orders/delete_pending_order/${id}`, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setDeletedPendingOrder({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setDeletedPendingOrder({ status: 3, payload: error })
    }
  })
}
