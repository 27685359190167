import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // State
  otpActivationState: { status: 0, payload: null },
  // Setter (Action)
  setOtpActivationState: action((state, payload = null) => {
    state.otpActivationState = payload
  }),
  // Action Creator
  otpActivation: thunk(async (actions, data) => {
    actions.setOtpActivationState({ status: 1, payload: null })

    const response = await fetch('/api/auth/otp_activation', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setOtpActivationState({ status, payload })
  }),
  // state for OTP redirect
  otpDetails: null,
  setOtpDetails: action((state, payload = null) => {
    state.otpDetails = payload
  }),
  passwordToConfirm: '',
  setPasswordToConfirm: action((state, payload = null) => {
    state.passwordToConfirm = payload
  })
}
