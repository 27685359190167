import { thunk, action } from 'easy-peasy'

export default {
  latestBonanzaPrompt: { status: 0, payload: null },
  setLatestBonanzaPrompt: action((state, payload = null) => {
    state.latestBonanzaPrompt = payload
  }),
  getLatestBonanzaPrompt: thunk(async (actions, data) => {
    actions.setLatestBonanzaPrompt({ status: 1, payload: null })
    try {
      const response = await fetch('/api/guest_service/bonanza_prompt/latest_prompt', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if ((response.status >= 200 && response.status <= 204) || response.status === 304) {
        actions.setLatestBonanzaPrompt({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setLatestBonanzaPrompt({ status: 3, payload: error.message })
    }
  }),
  updatedBonanzaPrompt: { status: 0, payload: null },
  setUpdatedBonanzaPrompt: action((state, payload = null) => {
    state.updatedBonanzaPrompt = payload
  }),
  putUpdateBonanzaPrompt: thunk(async (actions, data) => {
    actions.setUpdatedBonanzaPrompt({ status: 1, payload: null })
    try {
      const response = await fetch('/api/guest_service/bonanza_prompt/update_latest_prompt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
      })
      const payload = await response.json()
      if ((response.status >= 200 && response.status <= 204) || response.status === 304) {
        actions.setUpdatedBonanzaPrompt({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setUpdatedBonanzaPrompt({ status: 3, payload: error.message })
    }
  })
}
