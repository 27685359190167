import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'
import { decryptSecretKeys, encryptSecretKeys } from 'utils/crypto_helpers'
import { PAYMONGO_SALT } from 'utils/constants'

export default {
  // used for selecting payment methods
  selectedPaymentMethod: null,
  setSelectedPaymentMethod: action((state, payload = 0) => {
    state.selectedPaymentMethod = payload
  }),

  // use to save the current created payment of user via paymongo card
  savedCardDetails: null,
  setSavedCardDetails: action((state, payload = null) => {
    state.savedCardDetails = payload
  }),

  // create paymongo payment method
  createdPaymongoCardState: { status: 0, payload: null },
  setCreatedPaymongoCardState: action((state, payload = null) => {
    state.createdPaymongoCardState = payload
  }),
  createPaymongoCard: thunk(async (actions, data) => {
    actions.setCreatedPaymongoCardState({ status: 1, payload: null })
    const encryptedData = encryptSecretKeys(data, PAYMONGO_SALT)
    const response = await fetch('/api/paymongo/create_card', {
      method: 'post',
      body: JSON.stringify({ data: encryptedData }),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)
    actions.setCreatedPaymongoCardState({ status, payload: decryptSecretKeys(payload.data, PAYMONGO_SALT) })
  }),

  attachPaymentState: { status: 0, payload: null },
  setAttachPaymentState: action((state, payload = null) => {
    state.attachPaymentState = payload
  }),
  attachPayment: thunk(async (actions, { intentId, data }) => {
    actions.setAttachPaymentState({ status: 1, payload: null })
    const encryptedData = encryptSecretKeys(data, PAYMONGO_SALT)
    const response = await fetch(`/api/paymongo/create_payment/${intentId}`, {
      method: 'post',
      body: JSON.stringify({ data: encryptedData }),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setAttachPaymentState({ status, payload: decryptSecretKeys(payload.data, PAYMONGO_SALT) })
  })
}
