import { thunk, action } from 'easy-peasy'
import { urlParams } from 'utils/url'
const getHeaders = {
  method: 'get',
  headers: { 'Content-Type': 'application/json' }
}
export default {
  // Past Orders
  pastOrders: { status: 0, payload: null },
  setPastOrders: action((state, payload = null) => {
    state.pastOrders = payload
  }),
  getPastOrders: thunk(async (actions, params) => {
    actions.setPastOrders({ status: 1, payload: null })
    try {
      const query = urlParams(params)
      const response = await fetch(`/api/carts/past_orders?${query}`, getHeaders)
      const payload = await response.json()
      if (response.status === 200) {
        actions.setPastOrders({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setPastOrders({ status: 3, payload: error })
    }
  }),
  // Order Details
  pastOrderDetails: { status: 0, payload: null },
  setPastOrderDetails: action((state, payload = null) => {
    state.pastOrderDetails = payload
  }),
  getPastOrderDetails: thunk(async (actions, id) => {
    actions.setPastOrderDetails({ status: 1, payload: null })
    try {
      const response = await fetch(`/api/carts/past_orders/${id}`, getHeaders)
      const payload = await response.json()
      if (response.status === 200) {
        actions.setPastOrderDetails({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setPastOrderDetails({ status: 3, payload: error })
    }
  })
}
