import { action, thunk } from 'easy-peasy'
import { defaultHeaders, responseHandler } from 'utils/api'
import { urlParams } from 'utils/url'

export default {
  // retrieve all stores
  stores: { status: 0, payload: null },
  setListStores: action((state, payload = null) => {
    state.stores = payload
  }),
  listStores: thunk(async (actions, params) => {
    actions.setListStores({ status: 1, payload: null })
    const query = urlParams(params)
    const response = await fetch(`/api/zeus_store_service/${query}`, {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setListStores({ status, payload })
  }),
  // // retrieve stores by location
  // storesByLocation: { status: 0, payload: null },
  // setStoresByLocation: action((state, payload = null) => {
  //   state.storesByLocation = payload
  // }),
  // getStoresByLocation: thunk(async (actions, params) => {
  //   actions.setStoresByLocation({ status: 1, payload: null })
  //   const response = await fetch(`/api/zeus_store_service/${urlParams(params)}`, {
  //     method: 'get',
  //     headers: {
  //       ...defaultHeaders,
  //       'Cache-Control': 'no-cache'
  //     }
  //   })

  //   const { status, payload } = await responseHandler(response)

  //   actions.setStoresByLocation({ status, payload })
  // }),

  // retrieve store operations
  storeOperations: { status: 0, payload: null },
  setGetStoreOperations: action((state, payload = null) => {
    state.storeOperations = payload
  }),
  getStoreOperations: thunk(async (actions, params) => {
    actions.setGetStoreOperations({ status: 1, payload: null })
    const response = await fetch(`/api/zeus_store_service/store_operating_hours/store_codes=${params.store_codes}`, {
      method: 'get',
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setGetStoreOperations({ status, payload })
  })
}
