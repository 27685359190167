import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'
import { urlParams } from 'utils/url'

export default {
  // Me
  me: { status: 0, payload: null },
  setMe: action((state, payload = null) => {
    state.me = payload
  }),
  getMe: thunk(async (actions, data) => {
    actions.setMe({ status: 1, payload: null })
    const response = await fetch(`/api/auth/me?${urlParams(data)}`, {
      method: 'get',
      headers: { ...defaultHeaders }
    })
    const { status, payload } = await responseHandler(response)

    actions.setMe({ status, payload })
  }),
  // Social Media to Verify
  socialMediaToVerify: null,
  setSocialMediaToVerify: action((state, payload = null) => {
    state.socialMediaToVerify = payload
  }),
  // Mobile to Verify
  accountToVerify: null,
  setAccountToVerify: action((state, payload = null) => {
    state.accountToVerify = payload
  }),
  // Forgot Password to Verify
  forgotPasswordToVerify: null,
  setForgotPasswordToVerify: action((state, payload = null) => {
    state.forgotPasswordToVerify = payload
  })
}
