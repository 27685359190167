import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // State
  resetPasswordState: { status: 0, payload: null },
  // Setter (Action)
  setResetPasswordState: action((state, payload = null) => {
    state.resetPasswordState = payload
  }),
  // Action Creator
  resetPassword: thunk(async (actions, data) => {
    actions.setResetPasswordState({ status: 1, payload: null })

    const response = await fetch('/api/auth/reset_password', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)
    actions.setResetPasswordState({ status, payload })
  }),
  otpConfirmState: '',
  setOtpConfirmState: action((state, payload = null) => {
    state.otpConfirmState = payload
  })
}
