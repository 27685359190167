import { action, thunk } from 'easy-peasy'
import { urlParams } from 'utils/url'

export default {
  paymentOptions: { status: 0, payload: null },
  setPaymentOptions: action((state, payload = null) => {
    state.paymentOptions = payload
  }),
  getPaymentOptions: thunk(async (actions, params) => {
    actions.setPaymentOptions({ status: 1, payload: null })

    try {
      const response = await fetch(`/api/zeus_parameter/payment_options?${urlParams(params)}`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })
      const payload = await response.json()
      if (response.status === 200) {
        actions.setPaymentOptions({ status: 2, payload })
      } else {
        throw payload
      }
    } catch (error) {
      actions.setPaymentOptions({ status: 3, payload: error })
    }
  })
}
