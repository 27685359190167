import { StoreProvider } from 'easy-peasy'
import { generateClientToken, clientToken } from 'utils/oauth'
import Router from 'next/router'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import store from '../store'
import Maintenance from 'pages/maintenance'

import 'antd/dist/antd.less'
import 'public/styles/app.less'
import { retrieveKey } from 'utils/secret_cache'

Router.events.on('routeChangeStart', (url) => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

export async function getStaticProps ({ req, res }) {
  let token = null

  if (retrieveKey('SECRET')) {
    token = await clientToken(req, res)

    if (!token) {
      await generateClientToken(req, res)
    }
  }

  return {
    props: !token
  }
}

// _app renders in both server and client
export default function App ({ Component, pageProps }) {
  return (
    <StoreProvider store={store}>
      <title>Peri-Peri Chicken</title>
      {process.env.NEXT_PUBLIC_IS_MAINTENANCE === '1'
        ? <Maintenance />
        : <Component {...pageProps} />
      }
    </StoreProvider>
  )
}

App.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object
}
