import { thunk, action } from 'easy-peasy'
import { responseHandler, defaultHeaders } from 'utils/api'

export default {
  // Login
  loginState: { status: 0, payload: null },
  setLoginState: action((state, payload = null) => {
    state.loginState = payload
  }),
  login: thunk(async (actions, data) => {
    actions.setLoginState({ status: 1, payload: null })

    const response = await fetch('/api/auth/login', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setLoginState({ status, payload })
  }),
  // login v2
  loginStateV2: { status: 0, payload: null },
  setLoginStateV2: action((state, payload = null) => {
    state.loginStateV2 = payload
  }),
  loginV2: thunk(async (actions, data) => {
    actions.setLoginStateV2({ status: 1, payload: null })

    const response = await fetch('/api/auth/v2/login', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        ...defaultHeaders,
        'Cache-Control': 'no-cache'
      }
    })

    const { status, payload } = await responseHandler(response)

    actions.setLoginStateV2({ status, payload })
  }),
  // Logout
  logoutState: { status: 0, payload: null },
  setLogoutState: action((state, payload = null) => {
    state.logoutState = payload
  }),
  logout: thunk(async (actions, data = {}) => {
    actions.setLogoutState({ status: 1, payload: null })

    const response = await fetch('/api/auth/logout', {
      method: 'post',
      body: JSON.stringify(data),
      headers: { ...defaultHeaders }
    })

    const { status, payload } = await responseHandler(response)

    actions.setLogoutState({ status, payload })
  })
}
